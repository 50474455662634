import React from "react";

const Logo = () => {
  return (
    <div className="md:w-[12rem] w-[9rem] text-white hover:text-lightBlue transition duration-300 ease">
      <svg
        viewBox="0 0 278 57"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_45_2)">
          <path
            d="M75.8098 38.0898C76.1098 40.5298 76.9898 41.4898 79.5098 41.4898C82.0298 41.4898 83.2098 40.5298 83.2098 38.0898V11.5298H90.5398V38.0998C90.5398 40.5398 91.7198 41.4998 94.2398 41.4998C96.7598 41.4998 97.6398 40.5398 97.9398 38.0998L101.12 11.5298H108.45L104.97 38.2498C103.79 47.0598 101.42 48.5398 94.1698 48.5398C90.9898 48.5398 88.5498 48.2398 86.7698 47.2098C84.9898 48.2498 82.6298 48.5398 79.4398 48.5398C72.1898 48.5398 69.8198 47.0598 68.6398 38.2498L65.0898 11.5298H72.4198L75.8198 38.0998L75.8098 38.0898Z"
            fill="currentColor"
            stroke="#020202"
            stroke-miterlimit="10"
          />
          <path
            d="M118.8 36.5399C118.8 40.3899 121.02 42.3099 125.46 42.3099C129.9 42.3099 132.05 41.0499 132.05 37.6499V37.3499H139.08V37.4999C139.08 44.8299 133.46 49.3399 125.46 49.3399C117.46 49.3399 111.77 44.2299 111.77 36.8299V23.1399C111.77 15.7399 117.39 10.6299 125.46 10.6299C133.53 10.6299 139.08 15.8099 139.08 23.1399V35.1299H118.8V36.5399ZM132.05 29.2099V23.4399C132.05 19.5899 129.83 17.6699 125.46 17.6699C121.09 17.6699 118.8 19.5899 118.8 23.4399V29.2099H132.05Z"
            fill="currentColor"
            stroke="#020202"
            stroke-miterlimit="10"
          />
          <path
            d="M150.92 0.5V12.04C152.84 11.15 155.06 10.63 157.51 10.63C165.58 10.63 171.2 15.74 171.2 23.14V36.83C171.2 44.23 165.58 49.34 157.51 49.34C149.44 49.34 143.89 44.16 143.89 36.83V0.5H150.92ZM157.5 42.31C161.94 42.31 164.16 40.39 164.16 36.54V23.44C164.16 19.59 161.94 17.67 157.5 17.67C153.06 17.67 150.91 19.59 150.91 23.44V36.54C150.91 40.39 153.06 42.31 157.5 42.31Z"
            fill="currentColor"
            stroke="#020202"
            stroke-miterlimit="10"
          />
          <path
            d="M185.25 38.09C185.55 40.53 186.43 41.49 188.95 41.49C191.47 41.49 192.35 40.53 192.65 38.09L195.83 11.52H203.16L199.68 38.24C198.5 47.05 196.13 48.53 188.88 48.53C181.63 48.53 179.26 47.05 178.08 38.24L174.53 11.52H181.86L185.26 38.09H185.25Z"
            fill="currentColor"
            stroke="#020202"
            stroke-miterlimit="10"
          />
          <path
            d="M207.229 0.5H214.259V7.16H207.229V0.5ZM207.229 11.53H214.259V48.53H207.229V11.53Z"
            fill="currentColor"
            stroke="#020202"
            stroke-miterlimit="10"
          />
          <path
            d="M240.31 28.6999C243.49 29.5099 245.64 32.2499 245.64 35.5099V36.8399C245.64 44.1699 240.16 49.3499 232.39 49.3499C224.62 49.3499 219.07 44.2399 219.07 36.8399V35.2899H226.1V36.5499C226.1 40.3999 228.17 42.3199 232.39 42.3199C236.61 42.3199 238.61 40.3999 238.61 37.2899C238.61 35.8799 238.24 35.2899 237.2 34.9999L224.4 31.5999C221.22 30.7899 219.07 28.0499 219.07 24.7899V23.1599C219.07 15.7599 224.55 10.6499 232.39 10.6499C240.23 10.6499 245.64 15.8299 245.64 23.1599V26.0499H238.61V23.4599C238.61 19.6099 236.54 17.6899 232.39 17.6899C228.24 17.6899 226.1 19.6099 226.1 22.9399C226.1 24.3499 226.62 25.0899 227.51 25.3099L240.31 28.7099V28.6999Z"
            fill="currentColor"
            stroke="#020202"
            stroke-miterlimit="10"
          />
          <path
            d="M256.73 36.5399C256.73 40.3899 258.95 42.3099 263.39 42.3099C267.83 42.3099 269.98 41.0499 269.98 37.6499V37.3499H277.01V37.4999C277.01 44.8299 271.39 49.3399 263.39 49.3399C255.39 49.3399 249.7 44.2299 249.7 36.8299V23.1399C249.7 15.7399 255.32 10.6299 263.39 10.6299C271.46 10.6299 277.01 15.8099 277.01 23.1399V35.1299H256.73V36.5399ZM269.98 29.2099V23.4399C269.98 19.5899 267.76 17.6699 263.39 17.6699C259.02 17.6699 256.73 19.5899 256.73 23.4399V29.2099H269.98Z"
            fill="currentColor"
            stroke="#020202"
            stroke-miterlimit="10"
          />
          <path
            d="M41.3198 56.43C41.1998 56.43 41.0898 56.34 41.0698 56.22L37.8898 37.47C37.5898 35.02 36.7898 34.27 34.4398 34.27C32.0898 34.27 30.9898 35.18 30.9898 37.42V56.17C30.9898 56.31 30.8798 56.42 30.7398 56.42H23.4098C23.2698 56.42 23.1598 56.31 23.1598 56.17V37.42C23.1598 35.15 22.1898 34.27 19.7098 34.27C17.3898 34.27 16.5498 35.04 16.2598 37.45L12.8498 56.21C12.8298 56.33 12.7198 56.42 12.5998 56.42H5.2698C5.1998 56.42 5.1198 56.39 5.0798 56.33C5.0398 56.27 5.0098 56.2 5.0298 56.12L8.5798 37.22C9.7898 28.25 12.3998 26.73 19.6298 26.73C23.1698 26.73 25.3098 27.11 26.9598 28.02C28.5898 27.11 30.8198 26.73 34.3598 26.73C41.5898 26.73 44.1998 28.25 45.4098 37.23L48.8898 56.11C48.8998 56.18 48.8898 56.26 48.8398 56.32C48.7898 56.38 48.7198 56.41 48.6498 56.41H41.3198V56.43Z"
            fill="currentColor"
            stroke="black"
            stroke-width="0.7"
            stroke-miterlimit="10"
          />
          <path
            d="M12.6093 56.1797H5.2793L8.8293 37.2797C10.0093 28.4697 12.3793 26.9897 19.6293 26.9897C22.8093 26.9897 25.1793 27.2897 26.9593 28.3197C28.7393 27.2797 31.1793 26.9897 34.3593 26.9897C41.6093 26.9897 43.9793 28.4697 45.1593 37.2797L48.6393 56.1797H41.3093L38.1293 37.4297C37.8293 34.9897 37.0193 34.0297 34.4293 34.0297C31.8393 34.0297 30.7293 34.9897 30.7293 37.4297V56.1797H23.3993V37.4297C23.3993 34.9897 22.2893 34.0297 19.6993 34.0297C17.1093 34.0297 16.2993 34.9897 15.9993 37.4297L12.5993 56.1797H12.6093Z"
            fill="currentColor"
            stroke="black"
            stroke-width="0.7"
            stroke-miterlimit="10"
          />
          <path
            d="M0.599609 51.8099C0.539609 51.8099 0.489609 51.7899 0.439609 51.7499C0.379609 51.6999 0.349609 51.6299 0.349609 51.5599V44.2299C0.349609 44.1099 0.439609 43.9999 0.559609 43.9799L19.3096 40.7999C21.7596 40.4999 22.5096 39.6999 22.5096 37.3499C22.5096 34.9999 21.5996 33.8999 19.3596 33.8999H0.599609C0.459609 33.8999 0.349609 33.7899 0.349609 33.6499V26.3199C0.349609 26.1799 0.459609 26.0699 0.599609 26.0699H19.3496C21.6196 26.0699 22.4996 25.0999 22.4996 22.6199C22.4996 20.2999 21.7296 19.4599 19.3196 19.1699L0.559609 15.7699C0.439609 15.7499 0.349609 15.6399 0.349609 15.5199V8.18994C0.349609 8.11994 0.379609 8.03994 0.439609 7.99994C0.489609 7.95994 0.539609 7.93994 0.599609 7.93994C0.619609 7.93994 0.629609 7.93994 0.649609 7.93994L19.5496 11.4899C28.5196 12.6999 30.0396 15.3099 30.0396 22.5399C30.0396 26.0799 29.6596 28.2199 28.7496 29.8699C29.6596 31.4999 30.0396 33.7299 30.0396 37.2699C30.0396 44.4999 28.5196 47.1099 19.5396 48.3199L0.649609 51.8099C0.649609 51.8099 0.619609 51.8099 0.599609 51.8099Z"
            fill="currentColor"
            stroke="black"
            stroke-width="0.7"
            stroke-miterlimit="10"
          />
          <path
            d="M23.1596 12.4097C23.2496 12.4297 23.3296 12.4497 23.4096 12.4797C24.6996 12.8097 25.7396 13.2297 26.5696 13.7397C26.6396 13.7797 26.7096 13.8297 26.7796 13.8797C29.2296 15.5097 29.7896 18.1097 29.7896 22.5497C29.7896 24.3197 29.6996 25.8397 29.4296 27.1497C29.4196 27.2497 29.3996 27.3397 29.3696 27.4297C29.1696 28.3497 28.8696 29.1597 28.4496 29.8797C28.8596 30.5897 29.1596 31.3997 29.3596 32.3197C29.3896 32.4097 29.4096 32.5097 29.4196 32.5997C29.6996 33.9297 29.7896 35.4897 29.7896 37.2797C29.7896 41.7197 29.2296 44.3197 26.7796 45.9497C26.7196 45.9997 26.6496 46.0397 26.5796 46.0797C26.5796 46.0797 26.5696 46.0897 26.5696 46.0797C25.7296 46.5997 24.6996 47.0097 23.4096 47.3497C23.3296 47.3697 23.2496 47.3897 23.1596 47.4197C22.1096 47.6697 20.8996 47.8897 19.4996 48.0797L14.1596 49.0597L13.8896 49.1097L6.34961 50.4997L6.08961 50.5497L0.599609 51.5597V44.2297L7.49961 43.0597L7.74961 43.0197L15.2296 41.7497L15.4896 41.6997L19.3496 41.0497C21.7896 40.7497 22.7596 39.9397 22.7596 37.3497C22.7596 36.2997 22.5896 35.4797 22.2196 34.8797C22.2096 34.8797 22.2096 34.8697 22.2096 34.8697C22.0796 34.6697 21.9296 34.4997 21.7596 34.3497H21.7496C21.1996 33.8797 20.4196 33.6497 19.3496 33.6497H0.599609V26.3197H19.3496C20.6996 26.3197 21.5996 25.9797 22.1296 25.2297C22.1396 25.2297 22.1396 25.2197 22.1396 25.2197C22.2596 25.0597 22.3596 24.8897 22.4196 24.6897C22.4196 24.6897 22.4396 24.6897 22.4296 24.6797C22.6596 24.1397 22.7596 23.4497 22.7596 22.6197C22.7596 20.1097 21.7896 19.2197 19.3496 18.9197L15.5296 18.2297H15.5196L15.2596 18.1797L7.76961 16.8197L7.50961 16.7697M6.34961 9.26973L6.08961 9.21973M14.1696 10.7397L13.8996 10.6897"
            stroke="black"
            stroke-width="0.7"
            stroke-miterlimit="10"
          />
          <path
            d="M34.3599 33.0098C30.8199 33.0098 28.5899 32.6198 26.9599 31.7198C25.3099 32.6398 23.1699 33.0098 19.6299 33.0098C12.3999 33.0098 9.78985 31.4898 8.57985 22.5098L5.02985 3.61982C5.01985 3.54982 5.02985 3.46982 5.07985 3.40982C5.12985 3.34982 5.19985 3.31982 5.26985 3.31982H12.5999C12.7199 3.31982 12.8199 3.40982 12.8499 3.52982L16.2499 22.2798C16.5399 24.6998 17.3799 25.4798 19.6999 25.4798C22.1799 25.4798 23.1499 24.5998 23.1499 22.3298V3.57982C23.1499 3.43982 23.2599 3.32982 23.3999 3.32982H30.7299C30.8699 3.32982 30.9799 3.43982 30.9799 3.57982V22.3298C30.9799 24.5698 31.9799 25.4798 34.4299 25.4798C36.8799 25.4798 37.5899 24.7398 37.8799 22.2998L41.0599 3.53982C41.0799 3.41982 41.1799 3.32982 41.3099 3.32982H48.6399C48.7099 3.32982 48.7799 3.35982 48.8299 3.41982C48.8799 3.47982 48.8999 3.54982 48.8799 3.62982L45.3999 22.5298C44.1899 31.4998 41.5798 33.0198 34.3498 33.0198L34.3599 33.0098Z"
            fill="currentColor"
            stroke="black"
            stroke-width="0.7"
            stroke-miterlimit="10"
          />
          <path
            d="M16.0093 22.3296C16.3093 24.7696 17.1893 25.7296 19.7093 25.7296C22.2293 25.7296 23.4093 24.7696 23.4093 22.3296V3.57959H30.7393V22.3296C30.7393 24.7696 31.9193 25.7296 34.4393 25.7296C36.9593 25.7296 37.8393 24.7696 38.1393 22.3296L41.3193 3.57959H48.6493L45.1693 22.4796C43.9893 31.2896 41.6193 32.7696 34.3693 32.7696C31.1893 32.7696 28.7493 32.4696 26.9693 31.4396C25.1893 32.4796 22.8293 32.7696 19.6393 32.7696C12.3893 32.7696 10.0193 31.2896 8.8393 22.4796L5.2793 3.57959H12.6093L16.0093 22.3296Z"
            fill="currentColor"
            stroke="black"
            stroke-width="0.7"
            stroke-miterlimit="10"
          />
          <path
            d="M52.9095 51.8097L34.0095 48.3297C25.0395 47.1197 23.5195 44.5097 23.5195 37.2797C23.5195 33.7397 23.9095 31.5097 24.8095 29.8797C23.8895 28.2297 23.5195 26.0897 23.5195 22.5497C23.5195 15.3197 25.0395 12.7097 34.0195 11.4997L52.8995 7.94971C52.8995 7.94971 52.9295 7.94971 52.9495 7.94971C53.0095 7.94971 53.0595 7.96971 53.1095 8.00971C53.1695 8.05971 53.1995 8.12971 53.1995 8.19971V15.5297C53.1995 15.6497 53.1095 15.7497 52.9895 15.7797L34.2395 19.1797C31.8195 19.4697 31.0395 20.3097 31.0395 22.6297C31.0395 25.1097 31.9195 26.0797 34.1895 26.0797H52.9395C53.0795 26.0797 53.1895 26.1897 53.1895 26.3297V33.6597C53.1895 33.7997 53.0795 33.9097 52.9395 33.9097H34.1895C31.9495 33.9097 31.0395 34.9097 31.0395 37.3597C31.0395 39.8097 31.7795 40.5197 34.2195 40.8097L52.9795 43.9897C53.0995 44.0097 53.1895 44.1097 53.1895 44.2397V51.5697C53.1895 51.6397 53.1595 51.7097 53.0995 51.7597C53.0495 51.7997 52.9995 51.8197 52.9395 51.8197C52.9195 51.8197 52.9095 51.8197 52.8895 51.8197L52.9095 51.8097Z"
            fill="currentColor"
            stroke="black"
            stroke-width="0.7"
            stroke-miterlimit="10"
          />
          <path
            d="M34.2095 18.93C31.7695 19.23 30.8095 20.11 30.8095 22.63C30.8095 25.15 31.7695 26.33 34.2095 26.33H52.9595V33.66H34.2095C31.7695 33.66 30.8095 34.84 30.8095 37.36C30.8095 39.88 31.7695 40.76 34.2095 41.06L52.9595 44.24V51.57L34.0595 48.09C25.2495 46.91 23.7695 44.54 23.7695 37.29C23.7695 34.11 24.0695 31.67 25.0995 29.89C24.0595 28.11 23.7695 25.75 23.7695 22.56C23.7695 15.31 25.2495 12.94 34.0595 11.76L52.9595 8.20996V15.54L34.2095 18.94V18.93Z"
            fill="currentColor"
            stroke="black"
            stroke-width="0.7"
            stroke-miterlimit="10"
          />
        </g>
        <defs>
          <clipPath id="clip0_45_2">
            <rect width="277.51" height="56.78" fill="currentColor" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default Logo;
