import { Suspense, lazy, useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import Logo from "./Components/Logo/Logo";

const Routes = lazy(() => import("./Routes"));
const Footer = lazy(() => import("./Components/Footer/Footer"));

function App() {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    const scrollToElement = (element) => {
      const offset =
        4 * parseFloat(getComputedStyle(document.documentElement).fontSize);
      const elementPosition =
        element.getBoundingClientRect().top + window.scrollY - offset;
      window.scrollTo({ top: elementPosition, behavior: "smooth" });
    };

    if (pathname === "/projects") {
      window.scrollTo(0, 0);
    } else if (pathname === "/") {
      if (hash === "") {
        window.scrollTo(0, 0);
      } else {
        const element = document.getElementById(hash.replace("#", ""));
        if (element) {
          scrollToElement(element);
        }
      }
    } else {
      window.scrollTo(0, 0);
    }
  }, [pathname, hash]);

  return (
    <Suspense
      fallback={
        <div className="fixed h-[100vh] w-full">
          <div className="flex flex-col items-center justify-center h-screen gap-4">
            <Logo />
            <div className="flex space-x-2 mt-4">
              <div
                className="w-3 h-3 bg-blue-500 rounded-full animate-bounce-high"
                style={{ animationDelay: "0.1s" }}
              ></div>
              <div
                className="w-3 h-3 bg-blue-500 rounded-full animate-bounce-high"
                style={{ animationDelay: "0.2s" }}
              ></div>
              <div
                className="w-3 h-3 bg-blue-500 rounded-full animate-bounce-high"
                style={{ animationDelay: "0.3s" }}
              ></div>
            </div>
          </div>
        </div>
      }
    >
      <Routes />
      <Footer />
    </Suspense>
  );
}

export default App;
